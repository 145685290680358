import React from "react";
import Typical from "react-typical";

import "./App.css";
import "./fonts/CeraPro/CeraProBlack.otf";
import "./fonts/CeraPro/CeraProBold.otf";
import "./fonts/CeraPro/CeraProMedium.otf";
import "./fonts/CeraPro/CeraProLight.otf";

import bodyMan from "./assets/bodyMan.svg";

function App() {
  return (
    <div className="App font-face-gm h-screen">
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-black">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <div className="fire">
              <div className="fire-left">
                <div className="main-fire"></div>
                <div className="particle-fire"></div>
              </div>
              <div className="fire-center">
                <div className="main-fire"></div>
                <div className="particle-fire"></div>
              </div>
              <div className="fire-right">
                <div className="main-fire"></div>
                <div className="particle-fire"></div>
              </div>
              <div className="fire-bottom">
                <div className="main-fire"></div>
              </div>
            </div>
          </div>
          <div>
            <div className="text-center">
              <a href="/" className="inline-flex -mt-16">
                <span className="sr-only">Burnout Studio</span>
                <img className="h-52 w-auto -mt-2" src={bodyMan} alt="" />
              </a>
              <p className="Bold text-sm Black text-[#ef5a00] uppercase tracking-wide">
                <Typical
                  steps={[
                    "We're on fire 🔥",
                    1250,
                    "Don't worry we're professional 😎",
                    1250,
                    "Smart people choose fire 🫵",
                    1250,
                  ]}
                  loop={Infinity}
                  wrapper="p"
                />
              </p>
              <h1 className="Bold mt-2 text-4xl font-extrabold text-white uppercase tracking-tight sm:text-6xl">
                Burnout{" "}
                <span className="Light sm:text-4xl text-2xl -ml-1">Studio</span>
              </h1>
              <p className="Light mt-2 text-sm sm:text-base text-white">
                Our website is under construction, but we are ready to go! We
                are preparing something amazing for you.
              </p>
              <div className="mt-6">
                <a
                  href="https://www.instagram.com/burnoutstud.io/"
                  target="_blank"
                  className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium uppercase rounded-lg group bg-gradient-to-br from-[#ef5a00] to-[#d43322] group-hover:from-[#ef5a00] group-hover:to-[#d43322] hover:text-white dark:text-white focus:ring-4 focus:outline-none"
                >
                  <span className="Medium relative px-5 py-1.5 transition-all ease-in duration-75 text-white bg-black rounded-md group-hover:bg-opacity-0">
                    Follow us
                  </span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
